<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Mutasi</strong>
              </h5>
            </template>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button variant="primary" v-b-modal.modal-input-mutasi
                  ><CIcon name="cil-plus" /> Tambah Data</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>

            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>

            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(actions)="item">
                    <div class="d-flex nowrap">
                      <b-button
                        variant="success"
                        class="mr-1"
                        v-c-tooltip.hover.click="'Apply'"
                        @click="applyMutasi(item.item)"
                        :disabled="item.item.status_mutasi == 2"
                        ><CIcon name="cil-check" /> {{ item.actions }}</b-button
                      >
                      <b-button
                        variant="info"
                        class="mr-1"
                        v-c-tooltip.hover.click="'Sub Mutasi'"
                        v-b-modal.modal-sub-mutasi
                        @click="is_data = item.item"
                        ><CIcon name="cil-info" /> {{ item.actions }}</b-button
                      >
                      <b-button
                        variant="warning"
                        class="mr-1"
                        v-c-tooltip.hover.click="'Edit Data'"
                        v-b-modal.modal-edit-mutasi
                        @click="is_data = item.item"
                        :disabled="item.item.status_mutasi == 2"
                        ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                      >
                      <b-button
                        variant="danger"
                        class="mr-1"
                        v-c-tooltip.hover.click="'Hapus Data'"
                        v-b-modal.modal-delete-mutasi
                        @click="is_data = item.item"
                        :disabled="item.item.status_mutasi == 2"
                        ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                      >
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <ModalInput 
      :listPetugas="listPetugas"
      :listGudang="listGudang"
      @alertFromChild="triggerAlert($event), getDatas()" 
    />
    <ModalEdit
      :data_edit="is_data"
      :listJenisAlergi="listJenisAlergi"
      :listKomposisiBarang="listKomposisiBarang"
      :listPetugas="listPetugas"
      :listGudang="listGudang"
      @alertFromChild="triggerAlert($event)"
      @tutupModal="getDatas()"
    />
    <ModalSubMutasi
      :data_edit="is_data"
      :listJenisAlergi="listJenisAlergi"
      :listKomposisiBarang="listKomposisiBarang"
      :listPetugas="listPetugas"
      :listGudang="listGudang"
      @alertFromChild="triggerAlert($event)"
      @tutupModal="getDatas()"
    />
    <ModalDelete
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </div>
</template>

<script>
import ModalDelete from "./modalDelete.vue";
import ModalInput from "./modalInput.vue";
import ModalEdit from "./modalEdit.vue";
import ModalSubMutasi from "./modalSubMutasi.vue";

export default {
  components: {
    ModalInput,
    ModalDelete,
    ModalEdit,
    ModalSubMutasi,
  },
  name: "master_etnis",
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      is_data: "",
      listKomposisiBarang: [],
      listJenisAlergi: [],
      listPetugas: [],
      listGudang: [],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "kode_mutasi",
          label: "Kode",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "petugas_penerima",
          label: "Penerima",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_status_mutasi",
          label: "Status",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_gudang_asal",
          label: "Asal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_gudang_tujuan",
          label: "Tujuan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_tanggal_mutasi",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getDatas();
    this.getList();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async applyMutasi(data){
      const vm = this
      vm.tableBusy = true
      try {
        const res = await vm.$axios.post('/mutasi/apply_mutasi', {...data, mutasi_id: data.mutasi_id})
        // console.log('delete sub', res)
        if(res.data.status == 200){
          vm.getDatas()
        }
      } catch (error) {
        console.log(error)
      } finally {
        vm.tableBusy = false
      }
    },
    async getDatas() {
      const vm = this
      vm.tableBusy = true;
      let mutasi = await vm.$axios.post("/mutasi/list");
      // console.log('mutasi', mutasi)
      vm.items = mutasi.data.data;
      for (let i = 0; i < vm.items.length; i++) {
        let x = vm.items[i];
        x.no = i + 1;
        x.nama_tanggal_mutasi = vm.$moment(x.tanggal_mutasi).format('LL')
        x.nama_gedung_asal_full = x.nama_gudang_asal + ' - ' + x.tipe_gudang_asal
        x.nama_gedung_tujuan_full = x.nama_gudang_tujuan + ' - ' + x.tipe_gudang_tujuan
        x.nama_status_mutasi = x.status_mutasi == 2 ? 'diproses' : 'dibuat' 
      }
      vm.totalRows = vm.items.length;
      vm.tableBusy = false;
    },
    async getList() {
      const vm = this
      vm.tableBusy = true;
      // user
      let listPetugas = await vm.$axios.post("/user/list",{
        ms_puskesmas_id: localStorage.getItem("idp")
      });
      // console.log('listPetugas', listPetugas)
      vm.listPetugas = listPetugas.data.status == 200 ? listPetugas.data.data : []
      // gudang
      let listGudang = await vm.$axios.post("/ms_gudang/list");
      // console.log('listGudang', listGudang)
      vm.listGudang = listGudang.data.status == 200 ? listGudang.data.data.map(x => { return {
        ...x,
        nama: x.nama_gudang + ' - ' + x.tipe_gudang
      }}) : []
      vm.tableBusy = false;
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    }
  },
};
</script>
