<template>
  <div>
    <b-modal
      id="modal-sub-mutasi"
      size="xl"
      centered
      title="Sub Mutasi"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="tutupModal"
      @show="getDatas()"
    >
      <b-row>
        <b-col cols="4">
          <div class="card p-3">
            <h3 class="text-center mb-4">Mutasi</h3>
            <b-row style="margin: 0px -8px;">
              <b-col cols="5">Tanggal</b-col>
              <b-col cols="7">: {{data_edit.nama_tanggal_mutasi || '-'}}</b-col>
              <b-col cols="5">Kode Mutasi</b-col>
              <b-col cols="7">: {{data_edit.kode_mutasi || '-'}}</b-col>
              <b-col cols="5">Petugas Penerima</b-col>
              <b-col cols="7">: {{data_edit.petugas_penerima || '-'}}</b-col>
              <b-col cols="5">Gudang Asal</b-col>
              <b-col cols="7">: {{data_edit.nama_gedung_asal_full || '-'}}</b-col>
              <b-col cols="5">Gudang Tujuan</b-col>
              <b-col cols="7">: {{data_edit.nama_gedung_tujuan_full || '-'}}</b-col>
              <b-col cols="5">Keterangan Mutasi</b-col>
              <b-col cols="7">: {{data_edit.keterangan_mutasi || '-'}}</b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="8">
          <div class="mt-2">
            <label for="ms_barang_id">Barang <span class="text-danger">*</span></label>
            <Multiselect
              id="ms_barang_id"
              v-model="$v.is_data.ms_barang_id.$model"
              :options="listBarang"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama"
              track-by="ms_barang_id"
              placeholder="-- Pilih Barang --"
              size="sm"
              @select="getStock(), getBatch(), is_data.kode_batch = null, is_data.stock_id = null"
              @remove="listStock = [], listBatch = [], is_data.kode_batch = null, is_data.stock_id = null"
            ></Multiselect>
          </div>
          <div class="mt-2">
            <label for="kode_batch">Kode Batch <span class="text-danger">*</span></label>
            <!-- <b-form-input
              id="kode_batch"
              :state="checkIfValid('kode_batch')"
              type="text"
              size="sm"
              v-model="$v.is_data.kode_batch.$model"
            ></b-form-input> -->
            <Multiselect
              id="kode_batch"
              v-model="$v.is_data.kode_batch.$model"
              :options="listBatch"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="kode_batch"
              track-by="kode_batch"
              placeholder="-- Pilih Batch --"
              size="sm"
              @select="is_data.stock_id = null, getStock()"
              @remove="is_data.stock_id = null, getStock()"
            ></Multiselect>
          </div>
          <div class="mt-2">
            <label for="stock_id">Stock <span class="text-danger">*</span></label>
            <Multiselect
              id="stock_id"
              v-model="$v.is_data.stock_id.$model"
              :options="listStock"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama"
              track-by="stock_id"
              placeholder="-- Pilih Stock --"
              size="sm"
              @select="is_data.kode_batch = {kode_batch: is_data.stock_id.kode_batch}, getStock()"
              @remove="getStock()"
            ></Multiselect>
          </div>
          <div class="mt-2">
            <label for="kode_batch">Quantity Mutasi <span class="text-danger">*</span></label>
            <b-form-input
              :state="checkIfValid('qty_mutasi')"
              type="number"
              size="sm"
              v-model="$v.is_data.qty_mutasi.$model"
            ></b-form-input>
          </div>
        </b-col>
        <b-col cols="12" class="mb-3" v-if="data_edit.status_mutasi == 1">
          <div class="d-flex justify-content-end">
            <b-button
              variant="primary"
              :disabled="busy || !isValid"
              @click="simpan()"
              >Tambah</b-button
            >
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row class="mt-3">
        <b-col cols="12" md="12" lg="12">
          <b-table
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            responsive
            show-empty
            small
            bordered
            striped
            hover
            :busy="busy"
          >
            <template #cell(actions)="item">
              <div class="d-flex nowrap">
                <b-button
                  variant="danger"
                  class="mr-1"
                  v-c-tooltip.hover.click="'Hapus Data'"
                  @click="deleteSub(item.item)"
                  :disabled="data_edit.status_mutasi == 2"
                ><CIcon name="cil-trash" /> {{ item.actions }}</b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="5" offset-md="7">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
          ></b-pagination>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-sub-mutasi')">
          Batal
        </b-button>
        <!-- <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        > -->
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, between } from "vuelidate/lib/validators";

// let maxQty = 0
export default {
  name: "modalEdit",
  props:[
    'data_edit',
  ],
  data() {
    return {
      test: 10,
      busy: false,
      button: "Simpan",
      listBarang: [],
      listStock: [],
      listBatch: [],
      is_data: {
        kode_batch: null,
        qty_mutasi: null,
        stock_id: null,
        ms_barang_id: null,
      },
      items: [],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "kode_batch",
          label: "Kode Batch",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_barang",
          label: "Barang",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "kode_produk",
          label: "Stock",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "qty_mutasi",
          label: "QTY",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
    };
  },
  computed: {
    computed_val: {
      get() {
        return this.is_data;
      },
      set(newVal) {
        this.is_data = newVal;
      },
    },
    formString() {
      return JSON.stringify(this.data_edit, 4, null);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
  },
  watch: {
    'is_data.stock_id'(newVal, oldVal){
      if(newVal != oldVal){
        // console.log(newVal)
        // maxQty = newVal.qty_stock
      }
    }
  },

  mixins: [validationMixin],
  validations: {
    is_data: {
      kode_batch: { required },
      qty_mutasi: { 
        required, 
        between(value){
          return between(1, this.is_data && this.is_data.stock_id ? this.is_data.stock_id.qty_stock : 0)(value)
        }
      },
      stock_id: { required },
      ms_barang_id: { required },
    },
  },
  mounted() {
    const vm = this
    vm.getList()
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    async getDatas() {
      const vm = this
      vm.busy = true;
      let mutasi = await vm.$axios.post("/sub_mutasi/list", {mutasi_id: vm.data_edit.mutasi_id});
      // console.log('mutasi', mutasi)
      vm.items = mutasi.data.data;
      for (let i = 0; i < vm.items.length; i++) {
        let x = vm.items[i];
        x.no = i + 1;
      }
      vm.totalRows = vm.items.length;
      vm.resetModal();
      vm.busy = false;
    },
    async getList() {
      const vm = this
      vm.busy = true;
      // barang
      let listBarang = await vm.$axios.post("/ms_barang/list");
      // console.log('listBarang', listBarang)
      vm.listBarang = listBarang.data.status == 200 ? listBarang.data.data.map(x => { return {
        ...x,
        nama: x.kode_produk + ' - ' + x.nama_barang
      }}) : []
      vm.busy = false;
    },
    async getBatch() {
      const vm = this
      vm.busy = true;
      // console.log('getBatch',vm.data_edit.gudang_asal_id, vm.is_data.ms_barang_id)
      if(vm.data_edit.gudang_asal_id && vm.is_data.ms_barang_id){
        // batch
        let listBatch = await vm.$axios.post("/stock/list_batch_by_gudang", {
          ms_gudang_id: vm.data_edit.gudang_asal_id, 
          ms_barang_id: vm.is_data.ms_barang_id.ms_barang_id,
        });
        // console.log('listBatch', listBatch)
        vm.listBatch = listBatch.data.status == 200 ? listBatch.data.data.map(x => { return {
          ...x,
        }}) : []
      }else{
        vm.listBatch = []
      }
      vm.busy = false;
    },
    async getStock() {
      const vm = this
      vm.busy = true;
      if(vm.data_edit.gudang_asal_id && vm.is_data.ms_barang_id){
        // stock
        let listStock = await vm.$axios.post("/stock/list", {
          ms_gudang_id: vm.data_edit.gudang_asal_id, 
          ms_barang_id: vm.is_data.ms_barang_id.ms_barang_id,
          kode_batch: vm.is_data.kode_batch ? vm.is_data.kode_batch.kode_batch : null,
        });
        // console.log('listStock', listStock)
        vm.listStock = listStock.data.status == 200 ? listStock.data.data.map(x => { return {
          ...x,
          nama: x.kode_batch + ' - ' + x.qty_stock
        }}) : []
      }else{
        vm.listStock = []
      }
      vm.busy = false;
    },
    async deleteSub(data){
      const vm = this
      vm.busy = true
      try {
        const res = await vm.$axios.post('/sub_mutasi/delete', {id: data.sub_mutasi_id})
        // console.log('delete sub', res)
        if(res.data.status == 200){
          vm.getDatas()
        }
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.$axios
        .post("/sub_mutasi/register", {
          ...vm.is_data,
          mutasi_id: vm.data_edit.mutasi_id,
          stock_id: vm.is_data.stock_id ? vm.is_data.stock_id.stock_id : null,
          kode_batch: vm.is_data.kode_batch ? vm.is_data.kode_batch.kode_batch : null,
          ms_barang_id: vm.is_data.ms_barang_id ? vm.is_data.ms_barang_id.ms_barang_id : null,
        }).then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENAMBAH SUB MUTASI",
              showing: true,
            });
            vm.getDatas()
            vm.resetModal();
            // vm.$bvModal.hide("modal-sub-mutasi");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: (res.data.message).toUpperCase(),
              showing: true,
            });
          }
        })
        .catch(() => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    tutupModal() {
      this.$emit("tutupModal");
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        kode_batch: null,
        qty_mutasi: null,
        stock_id: null,
        ms_barang_id: null,
      };
    },
  },
};
</script>
