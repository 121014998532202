<template>
  <div>
    <b-modal
      id="modal-input-mutasi"
      size="lg"
      centered
      title="Tambah Data Mutasi"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tanggal <span class="text-danger">*</span>
          </template>
          <DatePicker
            style="width: 100%"
            id="tanggal_daftar"
            format="DD-MM-YYYY"
            :state="checkIfValid('tanggal_mutasi')"
            v-model="$v.is_data.tanggal_mutasi.$model"
          ></DatePicker>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Kode Mutasi <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('kode_mutasi')"
            type="text"
            v-model="$v.is_data.kode_mutasi.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Petugas
          </template>
          <Multiselect
            id="user_id"
            v-model="$v.is_data.petugas_id.$model"
            :options="listPetugas"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama"
            track-by="user_id"
            placeholder="-- Pilih Petugas --"
            size="sm"
            @select="is_data.petugas_penerima = is_data.petugas_id.nama"
            @remove="is_data.petugas_penerima = null"
          ></Multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Petugas Penerima <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('petugas_penerima')"
            type="text"
            v-model="$v.is_data.petugas_penerima.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Gudang Asal <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="ms_gudang_id"
            v-model="$v.is_data.gudang_asal_id.$model"
            :options="listGudang"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama"
            track-by="ms_gudang_id"
            placeholder="-- Pilih Gudang Asal --"
            size="sm"
            :disabled="items.length > 0"
            @select="getBatch, is_data_sub.ms_barang_id = null, is_data_sub.kode_batch = null, is_data_sub.stock_id = null"
            @remove="listBatch = [], is_data_sub.ms_barang_id = null, is_data_sub.kode_batch = null, is_data_sub.stock_id = null"
          ></Multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Gudang Tujuan <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="ms_gudang_id"
            v-model="$v.is_data.gudang_tujuan_id.$model"
            :options="listGudang"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama"
            track-by="ms_gudang_id"
            placeholder="-- Pilih Gudang Tujuan --"
            size="sm"
          ></Multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Keterangan Mutasi <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            :state="checkIfValid('keterangan_mutasi')"
            type="text"
            v-model="$v.is_data.keterangan_mutasi.$model"
          ></b-form-textarea>
        </b-form-group>
        <hr>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Barang <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="ms_barang_id"
            v-model="$v.is_data_sub.ms_barang_id.$model"
            :options="listBarang"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama"
            track-by="ms_barang_id"
            placeholder="-- Pilih Barang --"
            size="sm"
            @select="getStock(), getBatch(), is_data_sub.kode_batch = null, is_data_sub.stock_id = null"
            @remove="listStock = [], listBatch = [], is_data_sub.kode_batch = null, is_data_sub.stock_id = null"
          ></Multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Kode Batch <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="kode_batch"
            v-model="$v.is_data_sub.kode_batch.$model"
            :options="listBatch"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="kode_batch"
            track-by="kode_batch"
            placeholder="-- Pilih Batch --"
            size="sm"
            @select="is_data_sub.stock_id = null, getStock()"
            @remove="is_data_sub.stock_id = null, getStock()"
          ></Multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Stock <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="stock_id"
            v-model="$v.is_data_sub.stock_id.$model"
            :options="listStock"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama"
            track-by="stock_id"
            placeholder="-- Pilih Stock --"
            size="sm"
            @select="is_data_sub.kode_batch = {kode_batch: is_data_sub.stock_id.kode_batch}, getStock()"
            @remove="getStock()"
          ></Multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Quantity Mutasi <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValidSub('qty_mutasi')"
            type="number"
            v-model="$v.is_data_sub.qty_mutasi.$model"
          ></b-form-input>
        </b-form-group>
        <div class="d-flex justify-content-end">
          <b-button
            variant="primary"
            :disabled="busy || !isValidSub"
            @click="tambahSub()"
            >Tambah</b-button
          >
        </div>
      </b-form>
      <b-table
        :items="items"
        :fields="fields"
        responsive
        show-empty
        small
        bordered
        striped
        hover
        :busy="busy"
      >
        <template #cell(no)="item">
          {{item.index + 1}}
        </template>
        <template #cell(actions)="item">
          <div class="d-flex nowrap">
            <b-button
              variant="danger"
              class="mr-1"
              v-c-tooltip.hover.click="'Hapus Data'"
              @click="items.splice(item.index, 1)"
            ><CIcon name="cil-trash" /> {{ item.actions }}</b-button>
          </div>
        </template>
      </b-table>
      <!-- <img :src="src1" /> -->
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-mutasi')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, between } from "vuelidate/lib/validators";

export default {
  name: "modalInput",
  props:[
    'listPetugas',
    'listGudang',
  ],
  data() {
    return {
      listBarang: [],
      listStock: [],
      listBatch: [],
      is_data: {
        tanggal_mutasi: new Date(this.tanggal_sesi),
        kode_mutasi: '',
        petugas_penerima: '',
        keterangan_mutasi: '',
        petugas_id: '',
        gudang_asal_id: '',
        gudang_tujuan_id: '',
      },
      is_data_sub: {
        kode_batch: null,
        qty_mutasi: null,
        stock_id: null,
        ms_barang_id: null,
      },
      items: [],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "kode_batch",
          label: "Kode Batch",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_barang",
          label: "Barang",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "kode_produk",
          label: "Stock",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "qty_mutasi",
          label: "QTY",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      src1: "",
      selected: null,
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    isValidSub() {
      return !this.$v.is_data_sub.$invalid;
    },
    isDirtySub() {
      return this.$v.is_data_sub.$anyDirty;
    },
    tanggal_sesi(){
      return this.$store.state.tanggal_sesi
    }
  },
  watch: {
    tanggal_sesi(newVal, oldVal){
      if(newVal != oldVal){
        this.is_data.tanggal_mutasi = new Date(this.tanggal_sesi)
      }
    }
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      tanggal_mutasi: { required },
      kode_mutasi: { required },
      petugas_penerima: { required },
      keterangan_mutasi: { required },
      petugas_id: {  },
      gudang_asal_id: { required },
      gudang_tujuan_id: { required },
    },
    is_data_sub: {
      kode_batch: { required },
      qty_mutasi: { 
        required, 
        between(value){
          return between(1, this.is_data_sub && this.is_data_sub.stock_id ? this.is_data_sub.stock_id.qty_stock : 0)(value)
        }
        // maxValue: maxValue(10),
        // maxValue: maxValue(maxQty) 
      },
      stock_id: { required },
      ms_barang_id: { required },
    },
  },
  mounted() {
    const vm = this
    vm.getList()
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    checkIfValidSub(fieldName) {
      const field = this.$v.is_data_sub[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    async getList() {
      const vm = this
      vm.busy = true;
      // barang
      let listBarang = await vm.$axios.post("/ms_barang/list");
      // console.log('listBarang', listBarang)
      vm.listBarang = listBarang.data.status == 200 ? listBarang.data.data.map(x => { return {
        ...x,
        nama: x.kode_produk + ' - ' + x.nama_barang
      }}) : []
      vm.busy = false;
    },
    async getBatch() {
      const vm = this
      vm.busy = true;
      // console.log('getBatch',vm.is_data.gudang_asal_id, vm.is_data_sub.ms_barang_id)
      if(vm.is_data.gudang_asal_id && vm.is_data_sub.ms_barang_id){
        // batch
        let listBatch = await vm.$axios.post("/stock/list_batch_by_gudang", {
          ms_gudang_id: vm.is_data.gudang_asal_id.ms_gudang_id, 
          ms_barang_id: vm.is_data_sub.ms_barang_id.ms_barang_id,
        });
        // console.log('listBatch', listBatch)
        vm.listBatch = listBatch.data.status == 200 ? listBatch.data.data.map(x => { return {
          ...x,
        }}) : []
      }else{
        vm.listBatch = []
      }
      vm.busy = false;
    },
    async getStock() {
      const vm = this
      vm.busy = true;
      if(vm.is_data.gudang_asal_id && vm.is_data_sub.ms_barang_id){
        // stock
        let listStock = await vm.$axios.post("/stock/list", {
          ms_gudang_id: vm.is_data.gudang_asal_id.ms_gudang_id, 
          ms_barang_id: vm.is_data_sub.ms_barang_id.ms_barang_id,
          kode_batch: vm.is_data_sub.kode_batch ? vm.is_data_sub.kode_batch.kode_batch : null,
        });
        // console.log('listStock', listStock)
        vm.listStock = listStock.data.status == 200 ? listStock.data.data.map(x => { return {
          ...x,
          nama: x.kode_batch + ' - ' + x.qty_stock
        }}) : []
      }else{
        vm.listStock = []
      }
      vm.busy = false;
    },
    async tambahSub(){
      const vm = this
      try {
        const x = JSON.parse(JSON.stringify(vm.is_data_sub))
        vm.items.push({
          ...x,
          stock_id: x.stock_id.stock_id,
          ms_barang_id: x.ms_barang_id.ms_barang_id,
          kode_batch: x.kode_batch.kode_batch,
          nama_barang: x.stock_id.nama,
          kode_produk: x.ms_barang_id.nama,
        })
        vm.is_data_sub = {
          kode_batch: null,
          qty_mutasi: null,
          stock_id: null,
          ms_barang_id: null,
        }
        this.$v.is_data_sub.$reset();
      } catch (error) {
        console.log(error)
      }
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      const bulk_sub_mutasi = vm.items.map(x => { return {
        ...x,
      }})
      vm.$axios
        .post("/mutasi/register", {
          ...vm.is_data,
          petugas_id: vm.is_data.petugas_id ? vm.is_data.petugas_id.user_id : null,
          gudang_asal_id: vm.is_data.gudang_asal_id ? vm.is_data.gudang_asal_id.ms_gudang_id : null,
          gudang_tujuan_id: vm.is_data.gudang_tujuan_id ? vm.is_data.gudang_tujuan_id.ms_gudang_id : null,
          status_mutasi: '1',
          bulk_sub_mutasi
        }).then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN MUTASI",
              showing: true,
            });
            this.$bvModal.hide("modal-input-mutasi");
            this.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: (res.data.message).toUpperCase(),
              showing: true,
            });
          }
        })
        .catch(() => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        tanggal_mutasi: new Date(this.tanggal_sesi),
        kode_mutasi: '',
        petugas_penerima: '',
        keterangan_mutasi: '',
        petugas_id: '',
        gudang_asal_id: '',
        gudang_tujuan_id: '',
      };
      this.is_data_sub = {
        kode_batch: null,
        qty_mutasi: null,
        stock_id: null,
        ms_barang_id: null,
      }
      this.items = [];
    },
  },
};
</script>
