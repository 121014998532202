<template>
  <div>
    <b-modal
      id="modal-edit-mutasi"
      size="lg"
      centered
      title="Update Data Mutasi"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="tutupModal"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tanggal <span class="text-danger">*</span>
          </template>
          <DatePicker
            style="width: 100%"
            id="tanggal_daftar"
            format="DD-MM-YYYY"
            :state="checkIfValid('tanggal_mutasi')"
            v-model="$v.is_data.tanggal_mutasi.$model"
          ></DatePicker>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Kode Mutasi <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('kode_mutasi')"
            type="text"
            v-model="$v.is_data.kode_mutasi.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Petugas
          </template>
          <Multiselect
            id="user_id"
            v-model="$v.is_data.petugas_id.$model"
            :options="listPetugas"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama"
            track-by="user_id"
            placeholder="-- Pilih Petugas --"
            size="sm"
            @select="is_data.petugas_penerima = is_data.petugas_id.nama"
            @remove="is_data.petugas_penerima = null"
          ></Multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Petugas Penerima <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('petugas_penerima')"
            type="text"
            v-model="$v.is_data.petugas_penerima.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Gudang Asal <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="ms_gudang_id"
            v-model="$v.is_data.gudang_asal_id.$model"
            :options="listGudang"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama"
            track-by="ms_gudang_id"
            placeholder="-- Pilih Gudang Asal --"
            size="sm"
            disabled
          ></Multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Gudang Tujuan <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="ms_gudang_id"
            v-model="$v.is_data.gudang_tujuan_id.$model"
            :options="listGudang"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama"
            track-by="ms_gudang_id"
            placeholder="-- Pilih Gudang Tujuan --"
            size="sm"
          ></Multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Keterangan Mutasi <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            :state="checkIfValid('keterangan_mutasi')"
            type="text"
            v-model="$v.is_data.keterangan_mutasi.$model"
          ></b-form-textarea>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit-mutasi')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "modalEdit",
  props:[
    'data_edit',
    'listPetugas',
    'listGudang',
  ],
  data() {
    return {
      busy: false,
      button: "Simpan",
      is_data: null,
    };
  },
  computed: {
    computed_val: {
      get() {
        return this.is_data;
      },
      set(newVal) {
        this.is_data = newVal;
      },
    },
    formString() {
      return JSON.stringify(this.data_edit, 4, null);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
  },
  watch: {
    data_edit(newVal) {
      const vm = this
      vm.is_data = JSON.parse(JSON.stringify(newVal));
      vm.is_data.tanggal_mutasi = new Date(vm.is_data.tanggal_mutasi)
      vm.is_data.petugas_id = vm.$findKey(vm.listPetugas, vm.is_data.petugas_id, true, 'user_id')
      vm.is_data.gudang_asal_id = vm.$findKey(vm.listGudang, vm.is_data.gudang_asal_id, true, 'ms_gudang_id')
      vm.is_data.gudang_tujuan_id = vm.$findKey(vm.listGudang, vm.is_data.gudang_tujuan_id, true, 'ms_gudang_id')
    },
  },

  mixins: [validationMixin],
  validations: {
    is_data: {
      tanggal_mutasi: { required },
      kode_mutasi: { required },
      petugas_penerima: { required },
      keterangan_mutasi: { required },
      petugas_id: {  },
      gudang_asal_id: { required },
      gudang_tujuan_id: { required },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.$axios
        .post("/mutasi/update", {
          ...vm.is_data,
          id: vm.is_data.mutasi_id,
          petugas_id: vm.is_data.petugas_id ? vm.is_data.petugas_id.user_id : null,
          gudang_asal_id: vm.is_data.gudang_asal_id ? vm.is_data.gudang_asal_id.ms_gudang_id : null,
          gudang_tujuan_id: vm.is_data.gudang_tujuan_id ? vm.is_data.gudang_tujuan_id.ms_gudang_id : null,
        }).then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH MUTASI",
              showing: true,
            });
            vm.$bvModal.hide("modal-edit-mutasi");
            vm.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: (res.data.message).toUpperCase(),
              showing: true,
            });
          }
        })
        .catch(() => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    tutupModal() {
      this.$emit("tutupModal");
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        nama_alergi: "",
      };
    },
  },
};
</script>
